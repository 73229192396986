var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.isLoad,
          expression: "isLoad",
        },
      ],
      staticClass: "bill-type-form",
    },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "formPanel",
            attrs: { form: _vm.form, submitBefore: _vm.submitBefore },
            on: { update: _vm.update },
          },
          "form-panel",
          _vm.submitConfig,
          false
        ),
        [
          _c(
            "col2-detail",
            [
              _c(
                "col2-block",
                { attrs: { title: "基础信息" } },
                [
                  this.form.id !== undefined
                    ? _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "单据编码",
                            rules: [
                              {
                                required: true,
                                message: "请填写单据编码",
                                trigger: "change",
                              },
                            ],
                            prop: "billtypeCode",
                          },
                        },
                        [
                          _c("div", {
                            domProps: {
                              textContent: _vm._s(_vm.form.billtypeCode),
                            },
                          }),
                        ]
                      )
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "单据类型",
                        rules: [
                          {
                            required: true,
                            message: "请填写单据类型",
                            trigger: "change",
                          },
                        ],
                        prop: "billtypeName",
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "请输入单据类型",
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.billtypeName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "billtypeName", $$v)
                          },
                          expression: "form.billtypeName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    { attrs: { label: "单据状态", prop: "isEnable" } },
                    [
                      _c("v-select", {
                        attrs: { options: _vm.billTypeOps },
                        model: {
                          value: _vm.form.isEnable,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "isEnable", $$v)
                          },
                          expression: "form.isEnable",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }