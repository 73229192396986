<template>
  <div class="bill-type-form" v-show="isLoad">
    <form-panel
      ref="formPanel"
      :form="form"
      v-bind="submitConfig"
      :submitBefore="submitBefore"
      @update="update"
    >
      <col2-detail>
        <col2-block title="基础信息">
          <!-- <el-form-item v-if="this.form.id !== undefined" label="单据编码">
            <div v-text="form.formType"></div>
          </el-form-item>
          <el-form-item v-else label="表单类型"  :rules="[{ required: true, message: '请输入表单类型', trigger: 'change' }]" prop="formType" :style="{ marginBottom: '20px' }">
            <v-input v-model="form.formType" placeholder="请输入" :width="width"/>
          </el-form-item> -->

          <el-form-item v-if="this.form.id !== undefined" label="单据编码" :rules="[{ required: true, message: '请填写单据编码', trigger: 'change' }]" prop="billtypeCode">
            <div v-text="form.billtypeCode"></div>
          </el-form-item>
          <!-- <el-form-item v-else label="单据编码" :rules="[{ required: true, message: '请填写单据编码', trigger: 'change' }]" prop="billtypeCode">
            <v-input v-model="form.billtypeCode" placeholder="请输入单据编码" :width="width" />
          </el-form-item> -->
          <el-form-item label="单据类型" :rules="[{ required: true, message: '请填写单据类型', trigger: 'change' }]" prop="billtypeName">
            <v-input v-model="form.billtypeName" placeholder="请输入单据类型" :width="width" />
          </el-form-item>
          <!-- <el-form-item label="所属组织" :rules="[{ required: true, message: '请选择所属组织', trigger: 'change' }]" prop="orgId">
            <v-select2 
                v-model="form.orgId"
                placeholder="所属组织"
                v-bind="orgParams"
                :width="width" />
          </el-form-item> -->
          <el-form-item label="单据状态"  prop="isEnable">
            <v-select v-model="form.isEnable" :options="billTypeOps" />
          </el-form-item>

        </col2-block>
      </col2-detail>
    </form-panel>
  </div>
</template>

<script>
import { queryURL, createURL, updateURL, getOrgListURL } from './api'
import { Col2Block, Col2Detail } from '@/components/bussiness'
import { billTypeOps } from './map'
import { orgParams } from 'common/select2Params'

export default {
  name: 'billTypeForm',
  components: {
    Col2Block,
    Col2Detail
  },
  data () {

    return {
      billTypeOps: billTypeOps(1),
      width: 200,
      isLoad: false,
      submitConfig: {
        queryUrl: queryURL,
        submitUrl: undefined,
        submitMethod: ''
      },
      form: {
        id: undefined,
        billtypeCode: '',
        billtypeName: '',
        isEnable: 1
      },
      orgParams
      
    }
  },
  
  mounted () {
    const { id } = this.$route.query
    if (id !== undefined) {
      this.form.id = id
      this.$setBreadcrumb('编辑')
      this.submitConfig.submitUrl = updateURL
      this.submitConfig.submitMethod = 'POST'
      this.$refs.formPanel.getData({
        id
      })
    } else {
      this.$setBreadcrumb('新增')
      this.submitConfig.submitUrl = createURL
      this.submitConfig.submitMethod = 'POST'
    }
    this.isLoad = true
  },
  methods: {
    update (data) {
      this.form.billtypeCode = data.billtypeCode
      this.form.billtypeName = data.billtypeName
      this.form.isEnable = data.isEnable
    },
    submitBefore (data) {
        return data
      
    }
  }
}
</script>
