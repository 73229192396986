// const baseURL = 'http://10.12.41.17:8080'
const baseURL = 'http://172.16.50.238:7020/manage'
// 获取列表接口
const getBillTypeListURL = `${API_CONFIG.butlerBaseURL}activiti/billtype/getBilltypePage`
// 查询数据接口
const queryURL = `${API_CONFIG.butlerBaseURL}activiti/billtype/getBilltypeDetail`
// 提交数据接口
const updateURL = `${API_CONFIG.butlerBaseURL}activiti/billtype/updateBilltype`
// 新增数据接口
const createURL = `${API_CONFIG.butlerBaseURL}activiti/billtype/addBilltype`
// 删除数据接口
const deleteURL = `${API_CONFIG.butlerBaseURL}activiti/billtype/delBilltype`
// 获取组织列表
// const getOrgListURL = `${API_CONFIG.butlerBaseURL}activiti/billtype/deleteBatch`

export {
    getBillTypeListURL,
    queryURL,
    updateURL,
    createURL,
    deleteURL
}